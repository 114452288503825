<template>
    <div class="course_">
        <header id="agentTitle">
            <div class="header_banner_img_box_">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG993.jpeg" alt="">
                <span class="count_down_">倒计时：<van-count-down  millisecond :time="time" format="HH:mm:ss:SS" style="color:#E71212"/></span>
            </div>
        </header>
        <section>
            <div class="mobile_imput_box_">
                <i class="phone_icon_"><img src="https://cdn.zhiyileiju.cn/WechatIMG1000.png" alt=""></i>
                <input type="number" class="phone_input_"  v-model="mobile_phone" placeholder="请输入手机号领取上课链接" @input="phoneCallBack">
            </div>
            <div class="buttom_img_box_ animation_scaling" @click="paymentButtom"><img src="https://cdn.zhiyileiju.cn/WechatIMG996.jpeg" alt=""></div>
            <div class="img_box_" style="margin-top: 0.6rem;">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG992.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG991.jpeg" alt="">
            </div>
            <div class="img_box_750_">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG989.jpeg" alt="">
                <i class="button_icon__" @click="backToTheTopCallback"><img src="https://cdn.zhiyileiju.cn/WechatIMG988.jpeg" alt=""></i>
            </div>
            <div class="img_box_">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG990.jpeg" alt="">
            </div>
            <div class="img_box_">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG995.jpeg" alt="">
            </div>

            
        </section>
        <footer v-if="fixedbtn">
            <div class="bottom_img_box_">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG1019.jpeg" alt="">
                <i class="buttom_icon__" @click="backToTheTopCallback"><img src="https://cdn.zhiyileiju.cn/WechatIMG994.jpeg" alt=""></i>
            </div>
        </footer>
    </div>
</template>
<script>
import { $payRiverOrder,$paymentQueryRiver,$paymentWechatconf,$orderConfirm } from "../../api/home_marriage_test.js"
export default {
    data() {
        return {
            time:10*60*1000,
            mobile_phone:'',
            fixedbtn:false, //是否隐藏底部固定按钮
        }
    },
    created() {
         if ( localStorage.getItem("user_id") === null ) {
            }else{
                let paymentQueryRiver_ = {
                    "paytype": 2,
                    "order_id": localStorage.getItem("user_id")
                }
                $paymentQueryRiver(paymentQueryRiver_).then( ( res ) => { //判断用户是否已支付
                    console.log(res.data.data.is_pay)
                    if ( res.data.data.is_pay === true) {
                        this.$router.replace({path:'/mini_program_page/course_succeed',query: {from:this.$route.query.from}});
                        }else{
                    }
                }).catch( ( err ) => {
                    // console.log('123')
                })

            }
        // console.log(this.$route.query.from)
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted() {
        this.getWxConfig()
    },
    computed:{
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods:{
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
         getWxConfig(){//微信内支付配置
            // let url = window.location.href;
                $paymentWechatconf({url:window.location.href}).then(res => {
                    // console.log('wx',res.data.data.data)
                    let wxconfig = res.data.data.data;
                    if (this.is_weixn) {
                        wx.config({
                            debug: false,
                            appId: wxconfig.appId,
                            timestamp: wxconfig.timestamp,
                            nonceStr: wxconfig.nonceStr,
                            signature: wxconfig.signature,
                            jsApiList: ['chooseWXPay']
                        });
                    }
                })
        },
        wechatPaymentApi() { //微信支付宝支付接口
         let data_ = { 
                        order_id:this.$route.params.id,
                        paytype: 2,
                    }
               
            $orderConfirm(data_).then( ( res ) => {

            })
        },
        phoneCallBack(){// 手机号输入触发
        // let val = this.mobile_phone;
            // let myreg = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/;
            // if (!myreg.test(val) || val == "") {
            //     this.$dialog({ message: "手机号填写不正确" });
            // }else{

            // }
        },
        paymentButtom(){//提交 or 支付
            let val = this.mobile_phone;
            let myreg = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/;
            if (!myreg.test(val) || val == "") {
                this.$dialog({ message: "手机号填写不正确" });
            }else{
                if ( localStorage.getItem("user_id") === null ) {
                    let payRiverOrder_ = {
                        "paytype":2,  // 支付方式 支付宝1 ，微信 2
                        "fromk":this.$route.query.from,  //渠道号
                        bind_iphone:this.mobile_phone
                    }
                    $payRiverOrder(payRiverOrder_).then( ( res ) => { //获取用户id
                        // console.log(res.data.data.id)
                        if ( res.status === 201) {
                            localStorage.setItem("user_id",res.data.data.id);
                            location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat_river/${res.data.data.id}`;
    
                        }
                    });
                }else{
                    let paymentQueryRiver_ = {
                        "paytype": 2,
                        "order_id": localStorage.getItem("user_id")
                    }
                    $paymentQueryRiver(paymentQueryRiver_).then( ( res ) => { //判断用户是否已支付
                        console.log(res.data.data.is_pay)
                        if ( res.data.data.is_pay === true) {
                            this.$router.replace({path:'/mini_program_page/course_succeed',query: {from:this.$route.query.from}});
                            }else{
                            location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat_river/${localStorage.getItem("user_id")}`;
                        }
                    }).catch( ( err ) => {
                        // console.log('123')
                    location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat_river/${localStorage.getItem("user_id")}`;
                    })
                }
            }   
                
        },
        backToTheTopCallback() { //锚点
            document.querySelector("#agentTitle").scrollIntoView({behavior: "smooth", block: "start" });
        }
    }
}
</script>

<style lang="scss" scoped>
    .course_{
        header{
            background: #FBF1DF;
            .header_banner_img_box_{
                position: relative;
                width: 7.5rem;
                .count_down_{
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    right: .13rem;
                    top: 6.02rem;
                    font-size: .28rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #E71212;
                }
            }
        }
        section{
            padding-bottom: 2rem;
            background: #FBF1DF;
            .mobile_imput_box_{
                position: relative;
                width: 6.64rem;
                height: 1.02rem;
                background: #FFFFFF;
                border: .03rem solid #EA1E18;
                border-radius: .20rem;
                margin: auto;
                .phone_icon_{
                    position: absolute;
                    width: .5rem;
                    top: 44%;
                    left: .46rem;
                    transform: translate(0,-50%);
                }
                .phone_input_{
                    position: absolute;
                    top: 50%;
                    left: 1.1rem;
                    width: 5rem;
                    transform: translate(0,-50%);
                    height: .64rem;
                    font-size: .32rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: .64rem;
                    border: none;
                    border-left: .02rem solid #979797;
                    border-radius: 0;
                    text-indent:.38rem;
                }
            }
            .buttom_img_box_{
                width: 4.08rem;
                margin:0 auto;
                margin-top: .3rem;
            }
            .img_box_{
                width: 7.05rem;
                margin: 0 auto;
                margin-bottom: 0.2rem;
            }
            .img_box_750_{
                position: relative;
                width: 7.5rem;
                margin-bottom: 0.2rem;
                .button_icon__{
                    position: absolute;
                    bottom: .08rem;
                    right: .2rem;
                    width: 2.33rem;
                }
            }

        }
        footer{
            position: fixed;
            bottom: 0;
            left: 0;
            overflow: hidden;
            background: #6A4419;
            .bottom_img_box_{
                width: 7.5rem;
                .buttom_icon__{
                    position: absolute;
                    width: 2.59rem;
                    right: .56rem;
                    top: 0;
                }
            }
        }
    }
</style>